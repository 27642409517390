import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'
import { H1Light, Text } from 'components/atoms/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import { Button, ButtonLine } from 'components/atoms/Button'
import media from 'styles/media'

const NotFoundPageContainer = styled.div`
  width: 100%;
  height: 100vh;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  isolation: isolate;

  ${media.sm.max} {
    height: auto;

    padding: 128px 24px;
    margin-top: 56px;
  }
`

const ImageWraper = styled.div`
  width: 90vw;

  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  z-index: 1;

  ${media.lg.max} {
    width: 120vw;
  }
`

const ContentConatiner = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`

const NotFoundPage: React.FC<PageProps<Queries.NotFoundPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.notFound
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <NotFoundPageContainer>
        <ContentConatiner>
          <div>
            <Text
              fontSize={108}
              lineHeight={108}
              fontWeight={900}
              align="center"
              dangerouslySetInnerHTML={{ __html: PAGE.notFoundBoldText! }}
            />
            <H1Light
              align="center"
              dangerouslySetInnerHTML={{ __html: PAGE.notFoundText! }}
            />
          </div>
          <Button TextButton2 as={Link} to={PAGE.notFoundButtonLink!}>
            <ButtonLine
              dangerouslySetInnerHTML={{ __html: PAGE.notFoundButtonText! }}
            />
          </Button>
        </ContentConatiner>

        <ImageWraper>
          <StaticImage
            src="../assets/images/map.png"
            alt="Flat Iamge"
            style={{ width: '100%', height: '100%' }}
          />
        </ImageWraper>
      </NotFoundPageContainer>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    wpPage(slug: { regex: "/404/" }) {
      notFound {
        notFoundBoldText
        notFoundText
        notFoundButtonText
        notFoundButtonLink
      }
      seo {
        ...WpSEO
      }
    }
  }
`
